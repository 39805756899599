import * as React from "react"
import {
    SiJavascript,
    SiGatsby,
    SiNextdotjs,
    SiStyledcomponents,
    SiContentful,
    SiGraphql,
    SiTailwindcss,
    SiPrismic,
    SiMapbox,
    SiRedux,
    SiPwa
} from "react-icons/si"
import {
    FaReact,
    FaSass,
    FaBootstrap,
    FaYoutube
} from "react-icons/fa"

const Technology = ({ text }) => {
    return (
        <>
            {text === "React" && (
                <span><FaReact style={{ transform: "scale(1.35)", marginRight: "20px" }} />React</span>
            )}
            {text === "Sass" && (
                <span><FaSass style={{ transform: "scale(1.35)", marginRight: "20px" }} />Sass</span>
            )}
            {text === "Bootstrap" && (
                <span><FaBootstrap style={{ transform: "scale(1.35)", marginRight: "20px" }} />Bootstrap</span>
            )}
            {text === "Youtube API" && (
                <span><FaYoutube style={{ transform: "scale(1.35)", marginRight: "20px" }} />Youtube API</span>
            )}
            {text === "JavaScript" && (
                <span><SiJavascript style={{ transform: "scale(1.35)", marginRight: "20px" }} />JavaScript</span>
            )}
            {text === "Gatsby" && (
                <span><SiGatsby style={{ transform: "scale(1.35)", marginRight: "20px" }} />Gatsby</span>
            )}
            {text === "Nextdotjs" && (
                <span><SiNextdotjs style={{ transform: "scale(1.35)", marginRight: "20px" }} />Next</span>
            )}
            {text === "Styled Components" && (
                <span><SiStyledcomponents style={{ transform: "scale(1.35)", marginRight: "20px" }} />Styled Components</span>
            )}
            {text === "Contentful CMS" && (
                <span><SiContentful style={{ transform: "scale(1.35)", marginRight: "20px" }} />Contentful</span>
            )}
            {text === "GraphQL" && (
                <span><SiGraphql style={{ transform: "scale(1.35)", marginRight: "20px" }} />GraphQL</span>
            )}
            {text === "TailwindCSS" && (
                <span><SiTailwindcss style={{ transform: "scale(1.35)", marginRight: "20px" }} />Tailwind css</span>
            )}
            {text === "Prismic" && (
                <span><SiPrismic style={{ transform: "scale(1.35)", marginRight: "20px" }} />Prismic</span>
            )}
            {text === "Mapbox" && (
                <span><SiMapbox style={{ transform: "scale(1.35)", marginRight: "20px" }} />Mapbox API</span>
            )}
            {text === "Redux" && (
                <span><SiRedux style={{ transform: "scale(1.35)", marginRight: "20px" }} />Redux</span>
            )}
            {text === "PWA" && (
                <span><SiPwa style={{ transform: "scale(1.35)", marginRight: "20px" }} />Progressive Web App</span>
            )}
        </>
    )
}

export default Technology
